import styled from 'styled-components'

import { MOBILE_500 } from "../../../styles/sizes";

export const TitleContainer = styled.div<ITitleContainerProps>`
  width: 100%;
  height: ${props => props.height ? props.height : "160px"};
  overflow: hidden;
  position: relative;
  text-align: center;

  @media (max-width: ${ MOBILE_500 }px ) {
    height: ${props => props.heightMobile ? props.heightMobile : "86px"} ;
  }

`
interface ITitleContainerProps {
  height?: string,
  heightMobile?: string,
  heightTablet?: string,
}

export const Title = styled.div<ITitleProps>`
  position: absolute;
  width: 100%;
  transition: top 0.3s;
  margin: 0;
  transition-delay: ${props => props.delay ? props.delay : '0s'};
  top: ${props => props.isVisible ? '0' : '100%'};

`

interface ITitleProps {
  isVisible: boolean,
  delay?: string,
}
